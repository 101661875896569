<template>
  <v-card color="#81B4EA">
    <v-card-title>
      <h2 class="mb-0">De {{ titulo }} para...</h2>
    </v-card-title>
    <table-v
      :cols="cols"
      :rows="para"
      :opts="opts"
      :hasExportCSV="false"
      :hasExportXLSX="false"
      :hasExportPDF="false"
      hasFilter
      @click="doPairing($event)"
      @contextmenu="contextMenu"
    ></table-v>
    <v-dialog v-model="rastrearColaboradorId" scrollable persistent max-width="600">
      <v-card>
        <v-card-title class="pb-1 pr-8">
          <h3>Rastreio</h3>
          <v-btn @click="rastrearColaboradorId = null; desAnteriores = []" style="position: absolute; right: 5px; top: 5px" icon>
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0 form_container">
          <table-v
            v-if="desAnteriores.length > 0"
            :cols="rastreioCols"
            :opts="opts"
            :rows="desAnteriores"
            :hasFilter="false"
            :disableHover="true"
            height="528px"
          ></table-v>
          <div v-else>
            Ninguém foi incluído como este colaborador.
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="px-5" dark depressed @click="rastrearColaboradorId = null; desAnteriores = []">
            Fechar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  components: {
    "table-v": () => import("@/components/table-v.vue"),
  },
  created: function () {
    this.doLoad();
  },
  computed: {
    ...mapGetters(["clientId"]),
    cols: function () {
      return [
        { key: "id", name: "ID" },
        { key: "matricula", name: "Matricula" },
        { key: "nome", name: "Nome" },
        {
          key: "pesquisador",
          name: "Pesquisador",
          align: 0,
          rel: { to: "pesquisador", key: "id", name: "nome" },
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
        },
      ];
    },
    rastreioCols: function () {
      return [
        { key: "nome", name: "Nome" },
      ];
    },
    resource: function () {
      return this.apiResource(
        `/v1/rh/colaboradores/${this.$route.params.colaboradorId}/depara`
      );
    },
    titulo: function () {
      if (this.de) {
        const { matricula, nome } = this.de;
        return `${matricula || '[sem matricula]'} — ${nome || '[sem nome]'}`;
      }
      return "colaborador";
    },
  },
  methods: {
    doLoad: function () {
      this.resource.get().then(({ error, para, de }) => {
        if (!error) {
          this.para = para;
          this.de = de;
        }
      });
    },
    doPairing: function (e) {
      if (!this.de || !e.id) {
        return;
      }

      

      this.showConfirm(
        "Cuidado! Esta ação não pode ser desfeita!",
        `Tem certeza que deseja mover todos os dados de <b>${this.de.matricula ? '#' + this.de.matricula + ' — ': ''} ${this.de.nome}</b> para <b>${e.matricula ? '#' + e.matricula + ' — ' : ''} ${e.nome}</b>?`,
        () => {
          this.$notify({
            group: "geral",
            duration: 7000,
            type: "info",
            title: "Movendo...",
            text: "Movendo os dados dos colaboradores...",
          });

          this.resource.save({ id: e.id }).then(() => {
            this.$notify({
              group: "geral",
              duration: 7000,
              type: "success",
              title: "Sucesso",
              text: "De->Para realizado com sucesso.",
            });

            this.$router.push({ name: "listagem-colaboradores", query: { filterId: e.id }});
          });
        }
      );
    },
    contextMenu: function (v, e) {
      const menu = [
        {
          name: "Editar",
          cb: (row) => {
            const routeData = this.$router.resolve({
              name: "edicao-colaborador",
              params: { colaboradorId: row.id },
            });
            window.open(routeData.href, "_blank");
          },
        },
        {
          name: "Rastrear",
          disabled: v.deparas_count < 1,
          cb: (row) => {
            this.apiResource(`/v1/rh/deparas/${row.id}`).get().then((response) => {
              this.desAnteriores = response;
              this.rastrearColaboradorId = e.id;
            });
          },
        },
      ];
      this.showContext({
        left: e.clientX,
        top: e.clientY,
        val: v,
        menu,
      });
    },
  },
  data: function () {
    return {
      de: null,
      desAnteriores: [],
      para: [],
      rastrearColaboradorId: null,
      opts: {
        pesquisador: [
          {
            id: 1,
            nome: "Sim",
          },
          {
            id: 0,
            nome: "Não",
          },
        ],
      },
      options: [
        {
          name: "Folha de Pagamento",
          limit: 1,
          icon: "",
          cb: (row) => {
            this.$router.push({
              name: "folha-pagamento-colaborador",
              params: { colaboradorId: row.id },
            });
          },
        },
        {
          name: "Cálculo do Dispêndio",
          limit: 1,
          icon: "",
          cb: (row) => {
            this.$router.push({
              name: "dispendio-rh",
              query: { colaboradorId: row.id },
            });
          },
        },
        { name: "sep" },
        {
          name: "De->Para",
          limit: 1,
          icon: "",
          cb: (row) => {
            this.$router.push({
              name: "de-para-colaboradores",
              params: { colaboradorId: row.id },
            });
          },
        },
        { name: "sep" },
        {
          name: "Editar",
          limit: 1,
          icon: "",
          cb: (row) => {
            this.$router.push({
              name: "edicao-colaborador",
              params: { colaboradorId: row.id },
            });
          },
        },
        {
          name: "Excluir",
          limit: -1,
          icon: "",
          cb: (e) => {
            this.doDelete(e);
          },
        },
      ],
    };
  },
  watch: {
    clientId: function () {
      this.$router.push({ name: "listagem-colaboradores" });
    },
  },
};
</script>

<style lang="scss" scoped>
.form_container {
  padding: 0 !important;
  margin-top: -1em;
}
</style>
